import React from 'react';
import logo from './logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="min-vh-100 d-flex flex-column">
        <header>
          <div className="container">
            <nav className="navbar navbar-dark bg-transparenet">
              <a className="navbar-brand" href="/">
                <img src={logo} alt="logo"></img>
              </a>
              <span className="navbar-text d-none d-sm-inline-block">License No. R4826 </span>
              <span className="navbar-text d-none d-sm-inline-block">(240) 452-0721 </span>
              <span className="navbar-text d-none d-sm-inline-block">info@anashelpinghands.com</span>
            </nav>
          </div>
        </header>
        <main className="my-auto">
          <div className="container">
            <h1 className="page-title">Coming soon</h1>
            <p className="page-description">Website currently under construction. Call all email if you have questions </p>
            <p>Stay connected</p>
            <nav className="footer-social-links">
              <a href="#!" className="social-link"><i className="mdi mdi-facebook-box"></i></a>
              <a href="#!" className="social-link"><i className="mdi mdi-instagram"></i></a>
            </nav>
          </div>
        </main>
      </div>
    </div>
  );
}

export default App;
